<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1 v-if="data && data.report && data.report.metadata">{{data.report.metadata.label}}</h1>
          <v-btn v-if="data && data.options && !data.options.hideDefaultPrint" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
          <span v-if="data.prints?.length>0">
            <v-btn v-for="(printable, p) of data.prints" :key="p" @click="printCustom(printable)" class="ml-2" :color="printable.printBtn?.color||'info'" :fab="printable.printBtn?.fab" :small="!printable.printBtn?.fab" :x-small="printable.printBtn?.fab"><v-icon v-if="printable.printBtn?.icon">{{printable.printBtn?.icon}}</v-icon>{{printable.printBtn?.name}}</v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row" v-if="data.report?.metadata?.filterByDate">
            <v-menu
                 v-if="!data.report?.metadata?.endDateOnly"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn v-if="!data.report?.metadata?.endDateOnly" @click="filters.endDate=filters.startDate" color="warning" class="mx-2 mt-2"><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-spacer/>
          </span>

          <span class="d-flex flex-row mt-2" v-if="data.report?.metadata?.filterByDate">
            <v-btn small class="mr-2" color="info" @click="dailyRecords">Today</v-btn>
            <v-btn small class="mr-2" color="info" @click="monthlyRecords">This Month</v-btn>
            <v-btn small class="mr-2" color="info" @click="yearlyRecords">This Year</v-btn>
          </span>

          <span class="d-flex flex-row mt-2" v-if="data.report?.metadata?.filterByUser && data.report?.metadata?.excludeUser">
            <v-autocomplete
                class="mr-1"
                :disabled="filters.excludeUser.length>0"
                v-model="filters.includeUser"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')+(item.deletedAt?' (DELETED USER)':'')"
                item-value="id"
                hide-details
                outlined
                multiple
                label="Include Users"
                clearable
            ></v-autocomplete>
            <v-btn :disabled="!filters.excludeUser && !filters.includeUser" class="mt-2" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="ml-1"
                :disabled="filters.includeUser.length>0"
                v-model="filters.excludeUser"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')+(item.deletedAt?' (DELETED USER)':'')"
                item-value="id"
                hide-details
                outlined
                label="Exclude Users"
                clearable
            ></v-autocomplete>
          </span>

          <span class="d-flex flex-row mt-2">
            <v-autocomplete
                v-if="data.report?.metadata?.filterByUser && !data.report?.metadata?.excludeUser"
                v-model="filters.user"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')+(item.deletedAt?' (DELETED USER)':'')"
                item-value="id"
                hide-details
                multiple
                outlined
                label="Include Users"
                clearable
            />
            <v-autocomplete
                v-if="data.report?.metadata?.filterByBranch"
                :class="(data.report?.metadata?.filterByUser && !data.report?.metadata?.excludeUser)?'ml-2':''"
                v-model="filters.branches"
                :items="allBranches"
                hide-details
                :multiple="!data.report?.metadata?.singleBranch"
                outlined
                label="Branches"
                clearable
            />
          </span>

          <span class="d-flex flex-row mt-2" v-if="data.report?.metadata?.filterByPaymentType">
            <v-autocomplete
                v-model="filters.paymentTypes"
                :items="paymentTypes"
                multiple
                item-text="name"
                item-value="id"
                hide-details
                outlined
                label="Payment Types"
                clearable
            ></v-autocomplete>
          </span>

          <div v-if="data.report?.metadata?.allFilters">
            <span v-bind="parseAttributes(row.attributes)" v-for="(row, r) of data.report?.metadata?.allFilters" :key="r">
              <template v-for="(filter, f) of row.filters">
                <v-menu
                    :key="f"
                    v-if="filter.inputType==='date'"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :label="filter.label"
                        v-model="filters[filter.filtersCustomKey]"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="parseAttributes(filter.attributes)"
                        hide-details
                        v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="filters[filter.filtersCustomKey]"/>
                </v-menu>
                <component v-else :is="filter.inputType" @click="filter.attributes?.content?handleEval(filter.attributes.content.mapper):null" :items="filter.attributes?.items?parsedDataAttributes[filter.attributes.items.value]:null" v-bind="parseAttributes(filter.attributes)" :label="filter.label" v-model="filters[filter.filtersCustomKey]" :key="f"><span v-html="filter.attributes.content?.value"></span></component>
              </template>
            </span>
          </div>

          <v-btn :loading="loader" class="mt-2" color="success" @click="fetchReport"><v-icon>mdi-magnify</v-icon>Run Query</v-btn>
        </v-col>
        <hr>
      </v-row>
      <v-row v-if="data && data.summaries && data.summaries.length>0">
        <v-col>
          <span>
            <component :class="component.class||''" v-for="(component, index) in data.summaries" :key="'component'+index" :is="component.element">
              {{component.content}}
            </component>
          </span>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint" v-if="data.exports?.length>0 && data.exportOptions">
        <v-col>
          <h3>Exports</h3>
          <span class="d-flex flex-row flex-wrap mt-2 align-center">
            <span>
              <v-select style="width: 150px;" outlined dense hide-details :items="data.exportOptions.types" label="Export As" v-model="data.exportOptions.type"/>
            </span>
            <span v-if="data.exportOptions.type==='csv'">
              <json-excel :stringifyLongNum="true" v-for="(exp, i) of data.exports.filter(x => x.type.includes('csv'))" :name="exp.name||`VEC Report.${exp.type}`" :key="i" :data="exp.items" :fields="exp.csvHeaders" :type="exp.type">
                <v-btn class="ml-2" :color="exp.color||'info'">{{exp.text||'Export as CSV'}}</v-btn>
              </json-excel>
            </span>
            <span v-if="data.exportOptions.type==='xlsx' || data.exportOptions.type==='xls'">
              <vue-excel-xlsx
                  :key="i"
                  v-for="(exp, i) of data.exports.filter(x => x.type.includes('xlsx') || x.type.includes('xls'))"
                  :data="exp.items"
                  :columns="exp.excelHeaders"
                  :file-name="exp.name||`VEC Report`"
                  :file-type="data.exportOptions.type"
                  :sheet-name="exp.sheet||'Data'"
              >
                <v-btn class="ml-2" :color="exp.color||'info'">{{exp.text||'Export for Excel'}}</v-btn>
              </vue-excel-xlsx>
            </span>
          </span>
          <span>Choose the preferred export type and export. CSV will export as a .xls file.</span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.datasets && data.datasets.length>0">
        <v-col>
          <v-card outlined v-for="(dataset, d) of data.datasets" :key="`datasetKey${d}`" class="mb-2">
            <template v-if="dataset.type==='chart'">
              <v-card-text>
                <div :id="`datasets${d}`" :style="`width: ${dataset.style.width||'100%'}; height: ${dataset.style.height||'600px'};`"></div>
              </v-card-text>
            </template>
            <template v-else-if="dataset.type==='table'">
              <v-card-title>{{dataset.title}}</v-card-title>
              <v-card-subtitle class="d-flex flex-column">
                <span v-for="(sub, s) of dataset.subtitles" :key="s">{{sub}}</span>
              </v-card-subtitle>
              <v-text-field v-if="dataset.options && dataset.options.allowSearch" class="px-3 hideOnPagePrint" label="Search" outlined dense v-model="dataset.search"/>
              <v-data-table
                  class="mb-2"
                  :headers="dataset.headers"
                  :items="dataset.items"
                  :items-per-page="dataset.options?.itemsPerPage||-1"
                  :search="dataset.search"
                  @click:row="(_, item) => item.item?.viewRoute?$router.push(item.item.viewRoute):null"
                  :footer-props="{
                  itemsPerPageOptions: dataset.options?.pageSizeOptions||[1, 5, 10, 20, 50, -1]
                }"
              />
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="data && data.charts && data.charts.length>0">
        <v-col>
          <v-card outlined v-for="(chart, c) of data.charts" :key="`chartKey${c}`" class="mb-2">
            <v-card-text>
              <div :id="`charts${c}`" :style="`width: ${chart.style.width||'100%'}; height: ${chart.style.height||'600px'};`"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="data && data.tables && data.tables.length>0">
        <v-col>
          <v-card v-for="(table, t) of data.tables" :key="`tableKey${t}`" outlined>
            <v-card-title>{{table.title}}</v-card-title>
            <v-card-subtitle class="d-flex flex-column">
              <span v-for="(sub, s) of table.subtitles" :key="s">{{sub}}</span>
            </v-card-subtitle>
            <v-text-field v-if="table.options && table.options.allowSearch" class="px-3 hideOnPagePrint" label="Search" outlined dense v-model="table.search"/>
            <v-data-table
                class="mb-2"
                :headers="table.headers"
                :items="table.items"
                :item-key="`${Math.random}-${new Date().getTime()}`"
                :items-per-page="table.options?.itemsPerPage||-1"
                :search="table.search"
                @click:row="(_, item) => item.item?.viewRoute?$router.push(item.item.viewRoute):null"
                :footer-props="{
                  itemsPerPageOptions: table.options?.pageSizeOptions||[1, 5, 10, 20, 50, -1]
                }"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dialog-snack ref="snackbar" :color="snackObj.color" :text="snackObj.text"/>
  </div>
</template>
<style scoped>
table{
  border-collapse: collapse;
}
tr{
  border-bottom: 1px solid #ccc;
}
</style>
<script>
import axios from 'axios';
import moment from 'moment'
import {mapGetters} from "vuex";
import { VBtn, VTextField, VCheckbox, VTextarea, VAutocomplete, VCombobox, VFileInput, VDatePicker, VInput, VOtpInput, VOverflowBtn, VRadio, VSlider, VRangeSlider, VSelect, VSwitch } from 'vuetify/lib';
import utils from "../../plugins/helpers"
import * as echarts from 'echarts';
export default {
  components: {
    VBtn, VTextField, VCheckbox, VTextarea, VAutocomplete, VCombobox, VFileInput, VDatePicker, VInput, VOtpInput, VOverflowBtn, VRadio, VSlider, VRangeSlider, VSelect, VSwitch
  },
  data () {
    return {
      echarts: echarts,

      init: true,

      utils: utils,
      deleteDialog: false,
      deleteConfirmed: true,
      loader: false,
      snackObj: {
        color: '',
        text: ''
      },

      filters: {
        startDate: null,
        endDate: null,
        includeUser: [],
        excludeUser: [],
        paymentTypes: [],
        branches: []
      },

      graphs: [],

      allBranches: [],

      data: {
        report: {},
        datasets: [],
        charts: [],
        tables: [],
        prints: [],
        exports: [],
        exportOptions: {}
      },

      parsedDataAttributes: {}
    }
  },
  async mounted(){
    echarts.disconnect();

    this.allBranches = this.getBranches.map(x => {
      return {
        text: x.name,
        value: x.id
      }
    });

    echarts.registerTheme('light', {
      "color": [
        "#d87c7c",
        "#919e8b",
        "#d7ab82",
        "#6e7074",
        "#61a0a8",
        "#efa18d",
        "#787464",
        "#cc7e63",
        "#724e58",
        "#4b565b"
      ],
      "backgroundColor": "rgba(0,0,0,0)",
      "textStyle": {},
      "title": {
        "textStyle": {
          "color": "#333333"
        },
        "subtextStyle": {
          "color": "#aaaaaa"
        }
      },
      "line": {
        "itemStyle": {
          "borderWidth": 1
        },
        "lineStyle": {
          "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false
      },
      "radar": {
        "itemStyle": {
          "borderWidth": 1
        },
        "lineStyle": {
          "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false
      },
      "bar": {
        "itemStyle": {
          "barBorderWidth": 0,
          "barBorderColor": "#ccc"
        }
      },
      "pie": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "scatter": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "boxplot": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "parallel": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "sankey": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "funnel": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "gauge": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "candlestick": {
        "itemStyle": {
          "color": "#c23531",
          "color0": "#314656",
          "borderColor": "#c23531",
          "borderColor0": "#314656",
          "borderWidth": 1
        }
      },
      "graph": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        },
        "lineStyle": {
          "width": 1,
          "color": "#aaaaaa"
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
          "#d87c7c",
          "#919e8b",
          "#d7ab82",
          "#6e7074",
          "#61a0a8",
          "#efa18d",
          "#787464",
          "#cc7e63",
          "#724e58",
          "#4b565b"
        ],
        "label": {
          "color": "#eeeeee"
        }
      },
      "map": {
        "itemStyle": {
          "areaColor": "#eeeeee",
          "borderColor": "#444444",
          "borderWidth": 0.5
        },
        "label": {
          "color": "#000000"
        },
        "emphasis": {
          "itemStyle": {
            "areaColor": "rgba(255,215,0,0.8)",
            "borderColor": "#444444",
            "borderWidth": 1
          },
          "label": {
            "color": "rgb(100,0,0)"
          }
        }
      },
      "geo": {
        "itemStyle": {
          "areaColor": "#eeeeee",
          "borderColor": "#444444",
          "borderWidth": 0.5
        },
        "label": {
          "color": "#000000"
        },
        "emphasis": {
          "itemStyle": {
            "areaColor": "rgba(255,215,0,0.8)",
            "borderColor": "#444444",
            "borderWidth": 1
          },
          "label": {
            "color": "rgb(100,0,0)"
          }
        }
      },
      "categoryAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#333"
        },
        "splitLine": {
          "show": false,
          "lineStyle": {
            "color": [
              "#ccc"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "rgba(250,250,250,0.3)",
              "rgba(200,200,200,0.3)"
            ]
          }
        }
      },
      "valueAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#333"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#ccc"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "rgba(250,250,250,0.3)",
              "rgba(200,200,200,0.3)"
            ]
          }
        }
      },
      "logAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#333"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#ccc"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "rgba(250,250,250,0.3)",
              "rgba(200,200,200,0.3)"
            ]
          }
        }
      },
      "timeAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#333"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#333"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#ccc"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "rgba(250,250,250,0.3)",
              "rgba(200,200,200,0.3)"
            ]
          }
        }
      },
      "toolbox": {
        "iconStyle": {
          "borderColor": "#999999"
        },
        "emphasis": {
          "iconStyle": {
            "borderColor": "#666666"
          }
        }
      },
      "legend": {
        "textStyle": {
          "color": "#333333"
        }
      },
      "tooltip": {
        "axisPointer": {
          "lineStyle": {
            "color": "#cccccc",
            "width": 1
          },
          "crossStyle": {
            "color": "#cccccc",
            "width": 1
          }
        }
      },
      "timeline": {
        "lineStyle": {
          "color": "#293c55",
          "width": 1
        },
        "itemStyle": {
          "color": "#293c55",
          "borderWidth": 1
        },
        "controlStyle": {
          "color": "#293c55",
          "borderColor": "#293c55",
          "borderWidth": 0.5
        },
        "checkpointStyle": {
          "color": "#e43c59",
          "borderColor": "#c23531"
        },
        "label": {
          "color": "#293c55"
        },
        "emphasis": {
          "itemStyle": {
            "color": "#a9334c"
          },
          "controlStyle": {
            "color": "#293c55",
            "borderColor": "#293c55",
            "borderWidth": 0.5
          },
          "label": {
            "color": "#293c55"
          }
        }
      },
      "visualMap": {
        "color": [
          "#bf444c",
          "#d88273",
          "#f6efa6"
        ]
      },
      "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "rgba(47,69,84,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
          "color": "#333333"
        }
      },
      "markPoint": {
        "label": {
          "color": "#eeeeee"
        },
        "emphasis": {
          "label": {
            "color": "#eeeeee"
          }
        }
      }
    });
    echarts.registerTheme('dark', {
      "color": [
        "#dd6b66",
        "#759aa0",
        "#e69d87",
        "#8dc1a9",
        "#ea7e53",
        "#eedd78",
        "#73a373",
        "#73b9bc",
        "#7289ab",
        "#91ca8c",
        "#f49f42"
      ],
      "backgroundColor": "#333333",
      "textStyle": {},
      "title": {
        "textStyle": {
          "color": "#eeeeee"
        },
        "subtextStyle": {
          "color": "#aaaaaa"
        }
      },
      "line": {
        "itemStyle": {
          "borderWidth": 1
        },
        "lineStyle": {
          "width": 2
        },
        "symbolSize": 4,
        "symbol": "circle",
        "smooth": false
      },
      "radar": {
        "itemStyle": {
          "borderWidth": 1
        },
        "lineStyle": {
          "width": 2
        },
        "symbolSize": 4,
        "symbol": "circle",
        "smooth": false
      },
      "bar": {
        "itemStyle": {
          "barBorderWidth": 0,
          "barBorderColor": "#ccc"
        }
      },
      "pie": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "scatter": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "boxplot": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "parallel": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "sankey": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "funnel": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "gauge": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        }
      },
      "candlestick": {
        "itemStyle": {
          "color": "#fd1050",
          "color0": "#0cf49b",
          "borderColor": "#fd1050",
          "borderColor0": "#0cf49b",
          "borderWidth": 1
        }
      },
      "graph": {
        "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
        },
        "lineStyle": {
          "width": 1,
          "color": "#aaaaaa"
        },
        "symbolSize": 4,
        "symbol": "circle",
        "smooth": false,
        "color": [
          "#dd6b66",
          "#759aa0",
          "#e69d87",
          "#8dc1a9",
          "#ea7e53",
          "#eedd78",
          "#73a373",
          "#73b9bc",
          "#7289ab",
          "#91ca8c",
          "#f49f42"
        ],
        "label": {
          "color": "#eeeeee"
        }
      },
      "map": {
        "itemStyle": {
          "areaColor": "#eee",
          "borderColor": "#444",
          "borderWidth": 0.5
        },
        "label": {
          "color": "#000"
        },
        "emphasis": {
          "itemStyle": {
            "areaColor": "rgba(255,215,0,0.8)",
            "borderColor": "#444",
            "borderWidth": 1
          },
          "label": {
            "color": "rgb(100,0,0)"
          }
        }
      },
      "geo": {
        "itemStyle": {
          "areaColor": "#eee",
          "borderColor": "#444",
          "borderWidth": 0.5
        },
        "label": {
          "color": "#000"
        },
        "emphasis": {
          "itemStyle": {
            "areaColor": "rgba(255,215,0,0.8)",
            "borderColor": "#444",
            "borderWidth": 1
          },
          "label": {
            "color": "rgb(100,0,0)"
          }
        }
      },
      "categoryAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#eeeeee"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#aaaaaa"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "#eeeeee"
            ]
          }
        }
      },
      "valueAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#eeeeee"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#aaaaaa"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "#eeeeee"
            ]
          }
        }
      },
      "logAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#eeeeee"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#aaaaaa"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "#eeeeee"
            ]
          }
        }
      },
      "timeAxis": {
        "axisLine": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisTick": {
          "show": true,
          "lineStyle": {
            "color": "#eeeeee"
          }
        },
        "axisLabel": {
          "show": true,
          "color": "#eeeeee"
        },
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": [
              "#aaaaaa"
            ]
          }
        },
        "splitArea": {
          "show": false,
          "areaStyle": {
            "color": [
              "#eeeeee"
            ]
          }
        }
      },
      "toolbox": {
        "iconStyle": {
          "borderColor": "#999999"
        },
        "emphasis": {
          "iconStyle": {
            "borderColor": "#666666"
          }
        }
      },
      "legend": {
        "textStyle": {
          "color": "#eeeeee"
        }
      },
      "tooltip": {
        "axisPointer": {
          "lineStyle": {
            "color": "#eeeeee",
            "width": "1"
          },
          "crossStyle": {
            "color": "#eeeeee",
            "width": "1"
          }
        }
      },
      "timeline": {
        "lineStyle": {
          "color": "#eeeeee",
          "width": 1
        },
        "itemStyle": {
          "color": "#dd6b66",
          "borderWidth": 1
        },
        "controlStyle": {
          "color": "#eeeeee",
          "borderColor": "#eeeeee",
          "borderWidth": 0.5
        },
        "checkpointStyle": {
          "color": "#e43c59",
          "borderColor": "#c23531"
        },
        "label": {
          "color": "#eeeeee"
        },
        "emphasis": {
          "itemStyle": {
            "color": "#a9334c"
          },
          "controlStyle": {
            "color": "#eeeeee",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
          },
          "label": {
            "color": "#eeeeee"
          }
        }
      },
      "visualMap": {
        "color": [
          "#bf444c",
          "#d88273",
          "#f6efa6"
        ]
      },
      "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "rgba(255,255,255,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
          "color": "#eeeeee"
        }
      },
      "markPoint": {
        "label": {
          "color": "#eeeeee"
        },
        "emphasis": {
          "label": {
            "color": "#eeeeee"
          }
        }
      }
    });

    await this.getAllData();
    this.init=false;
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes', 'getBranches', 'lookupBranch', 'getUser', 'darkTheme']),
  },
  watch: {
    darkTheme(){
      this.renderCharts();
    }
  },
  methods: {
    rowClick(row){
      this.$router.push({ path: `/orders/view/${row.id}`})
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.color = color;

      this.$refs.snackbar.openDialog();
    },
    async getAllData(){
      try {
        this.loader = true;

        let res = await axios.get(`${this.getEndpoint}/api/customControllers/name/${this.$route.params.customReport}`)
        if(res.data.error) throw res.data.error
        if(!res.data.data) throw "Report Controller Could Not Be Found."
        let con = res.data.data

        if(con.metadata?.filterByDate){
          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

          if(this.$route.query.startDate){
            firstDay = new Date(this.$route.query.startDate);
          }
          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);
        }

        if(con.metadata?.filterByBranch && this.$route.query.branches){
          let branches = []

          if(Array.isArray(this.$route.query.branches)){
            branches = this.$route.query.branches.map(x => parseInt(x));
          }
          else{
            branches = [parseInt(this.$route.query.branches)]
          }

          if(branches.length>0 && con?.metadata?.singleBranch){
            branches = branches[0]
          }

          this.filters.branches = branches;
        }

        for(let filter of Object.keys(this.$route.query)){
          let excludes = ['startDate', 'endDate', 'branches']
          if(excludes.includes(filter)) continue;

          this.filters[filter] = this.$route.query[filter];

          if(Array.isArray(this.filters[filter])){
            this.filters[filter] = this.filters[filter].map(x => !isNaN(parseInt(x))?parseInt(x):x);
          }
          else if(["true", "false"].includes(this.filters[filter])){
            this.filters[filter] = this.filters[filter]==="true"
          }
        }

        if(!con.metadata){
          con.metadata = {};
        }
        if(!con.metadata.allFilters){
          con.metadata.allFilters = []
        }

        for(let row of con.metadata.allFilters){
          for(let filter of row.filters){
            await this.loadParsedAttributes(filter.attributes, true);
          }
        }

        this.data.report = con;

        //if(this.init) await this.fetchReport();
      } catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    generateGraph(i, set='charts'){
      let chart = this.data[set][i];
      let found = this.graphs.find(x => x.id===chart.id)
      if(found){
        echarts.dispose(found.graph);
        found.graph = echarts.init(document.getElementById(set + i), this.darkTheme?'dark':'light');
      }
      else{
        this.graphs.push({id: chart.id, graph: echarts.init(document.getElementById(set + i), this.darkTheme?'dark':'light')});
        found = this.graphs[this.graphs.length-1];
      }

      if(chart.style?.percentLabel){
        chart.option.series[0].label.formatter = function(params) {
          let percent = (params.percent).toFixed(chart.style.percentFloats||0) + '%';
          return params.name + '\n' + percent;
        }
      }

      if(chart.style?.hideZero){
        chart.option.label.formatter = function(params) {
          if (params.value !== 0) {
            return params.value;
          } else {
            return '';
          }
        }
      }

      if(chart.style?.parseLegend){
        if(chart.style.parseLegend.type==="function"){
          let fn = new Function(chart.style.parseLegend.data)
          chart.option.legend.formatter = fn;
        }
      }

      found.graph.setOption(chart.option, {notMerge: true})

      this.$forceUpdate()
    },
    async fetchReport(){
      try {
        this.loader = true;

        if(this.getUser.userTypeId===1) {
          console.log(this.filters)
        }

        let res = await axios.post(`${this.getEndpoint}/api/custom/${this.$route.params.customReport}`, {...this.filters, report: this.$route.params.customReport})
        if(res.data.error) throw res.data.error
        this.data = {...this.data, ...res.data.data}

        if(this.getUser.userTypeId===1){
          console.log(this.data)
        }

        let changed = false;
        let tempObj = {...this.filters}
        delete tempObj.product

        if(Object.keys(this.$route.query).length!==Object.keys(tempObj).length) changed = true;
        if(!changed){
          for(let key of Object.keys(this.$route.query)){
            if(tempObj[key]!=this.$route.query[key] && !Array.isArray(tempObj[key])){
              if((this.$route.query[key]==='true' && tempObj[key]) || (this.$route.query[key]==='false' && !tempObj[key])) continue;
              changed = true;
              break;
            }
            if(Array.isArray(tempObj[key])){
              if(Object.keys(this.$route.query[key]).length!==Object.keys(tempObj[key]).length){
                changed = true;
                break;
              }
              for(let i=0;i<tempObj[key].length;i++){
                if(tempObj[key][i]!=this.$route.query[key][i]){
                  changed = true;
                  break;
                }
              }
            }
          }
        }

        if(changed) await this.$router.replace({query: {...tempObj}});

        this.$forceUpdate();

        this.renderCharts();

      } catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    renderCharts(){
      if(this.data.charts && this.data.charts.length){
        for(let i=0; i<this.data.charts.length; i++){
          this.generateGraph(i);
        }
      }

      if(this.data.datasets && this.data.datasets.length){
        for(let i=0; i<this.data.datasets.length; i++){
          if(this.data.datasets[i].type!=='chart') continue;
          this.generateGraph(i, 'datasets');
        }
      }
    },
    async dailyRecords(){
      this.filters.startDate = moment().format("YYYY-MM-DD");
      this.filters.endDate = moment().format("YYYY-MM-DD");
    },
    async monthlyRecords(){
      this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
      this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
    },
    async yearlyRecords(){
      this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
      this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
    },
    async swapUserFields(){
      if(this.filters.includeUser.length>0){
        let temp = this.filters.includeUser;
        this.filters.includeUser = [];
        this.filters.excludeUser = temp;
      }
      else if(this.filters.excludeUser.length>0){
        let temp = this.filters.excludeUser;
        this.filters.excludeUser = [];
        this.filters.includeUser = temp;
      }
    },
    async printData(){
      let els = document.querySelectorAll(".hideOnPagePrint");
      let defaults = [];
      for(let el of els){
        defaults.push(el.style.display);
        el.style.setProperty("display", "none", "important");
      }

      let els2 = document.querySelectorAll(".adjustForPagePrint");
      let defaults2 = [];
      // let defaults3 = [];
      for(let el of els2){
        defaults2.push(el.style.padding);
        // defaults3.push(el.style.paddingRight);
        el.style.setProperty("padding", "0px 50px 0px 0px");
        // el.style.setProperty("paddingRight", "20px");
      }

      window.print();

      for(let i=0;i<els.length;i++){
        els[i].style.setProperty("display", defaults[i], "");
      }

      for(let i=0;i<els2.length;i++){
        els2[i].style.setProperty("padding", defaults2[i], "");
      }
    },
    printCustom(printable){
      try{
        const ifrm = document.createElement('iframe')
        ifrm.style.display = "none"
        ifrm.src = `${this.getEndpoint}/api/custom/${this.$route.params.customReport}`
        document.body.appendChild(ifrm)

        const iframeDoc = ifrm.contentDocument || ifrm.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(printable.printObject.job.htmlToPrint);
        iframeDoc.close();

        ifrm.onload = function(){
          ifrm.contentWindow.print()
          document.body.removeChild(ifrm)
        }
      }
      catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    parseAttributes(attrs){
      let obj = {}

      for(let key of Object.keys(attrs)){
        if(['item-text', 'item-value'].includes(key)){
          obj[key] = eval(attrs[key].value)
          continue;
        }
        else if(['items'].includes(key)){
          continue;
        }

        obj[key] = attrs[key].value
      }

      return {...obj, ...this.$attrs}
    },
    async loadParsedAttributes(attrs, force=false){
      try{
        if(attrs.items){
          if(attrs.items.value && (force || !this.parsedDataAttributes[attrs.items.value])){
            if(attrs.items.value && attrs.items.route && attrs.items.mapper){
              let res = await axios.get(eval(attrs.items.route))
              if(res.data.error) throw res.data.error

              let data = res.data.data;
              if(this.getUser.userTypeId===1){
                console.log(data)
              }
              let mapped = eval(attrs.items.mapper)

              this.parsedDataAttributes[attrs.items.value] = mapped;
            }
          }
        }
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    handleEval(data){
      eval(data);
      this.$forceUpdate();
    }
  }
}
</script>
